


















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ImportResult} from "@/models/import/ImportResult";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

@Component({
    components: {
        VuePerfectScrollbar
    }
})
export default class ImportResultModal extends Vue {
    @Prop({default: () => []}) data: ImportResult[];
    @Prop({default: false}) isLoading: boolean;

    isShow = false;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };

    @Watch('isLoading')
    onLoading(value: boolean) {
        if (value) {
            this.$vs.loading({
                container: '#div-with-loading',
                scale: 1
            })
        } else {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
        }
    }

    show() {
        this.isShow = true;
    }
}
